<template>
  <div>
    <el-dialog
        title="投递简历"
        @close="close"
        :visible.sync="dialogVisible"
        width="400px"
    >
      <el-form
          :model="form"
          ref="numberValidateForm"
          class="demo-ruleForm"
          :rules="rules"
      >
        <el-form-item prop="name" required :show-message="false">
          <p class="itemTitle">姓名 <span>*</span></p>
          <el-input
              size="small"
              v-model.number="form.name"
              autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="tel">
          <p class="itemTitle">手机 <span>*</span></p>
          <el-input
              type="number"
              size="small"
              v-model.number="form.tel"
              autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="position" required>
          <p class="itemTitle">电子简历 <span>*</span></p>
          <div class="tips">
            简历建议使用 PDF 文件，也支持DOC、DOCX、JPG、PNG
            、ZIP、RAR等格式,文件大小不超过8M
          </div>
          <fileUpload
              v-if="!form.position.file_name"
              ref="uploadComponents"
              @uploadCompleteOSS="uploadComplete"
              :accept="fileUploadOptions.accept"
              :autoUpload="fileUploadOptions.autoUpload"
              :limitSize="fileUploadOptions.limitSize"
          >
            <el-button type="primary" size="small" icon="el-icon-upload"
            >上传简历</el-button
            >
          </fileUpload>

          <div class="upPosition" v-else>
            <p>{{ form.position.file_name }}</p>
            <i
                @click="form.position = { file_name: '', url: '' }"
                class="el-icon-delete"
            ></i>
          </div>
        </el-form-item>
        <el-form-item class="btns">
          <el-button class="btn" @click="dialogVisible = false">取消</el-button>
          <el-button
              class="btn"
              type="primary"
              @click="submitForm('numberValidateForm')"
          >确认提交</el-button
          >
        </el-form-item>
      </el-form>
      <p style="text-align:center">
        投递代表同意我们的<a @click="innerVisible=true;" style="color: #409eff;cursor:pointer"
      >《隐私协议》</a
      >
      </p>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
      <el-dialog
          width="700px"
          title="隐私政策"
          :visible.sync="innerVisible"
          append-to-body
      >
        <div>
          <p class="Tcenter strong">危险品安全联盟 平台隐私政策</p> <br>
          <p class="indent"> 您的信任对我们非常重要，我们非常重视保护您的隐私。我们将按照法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴于此，我们作为危险品安全联盟（以下简称“DGA平台”）服务提供者制定本《平台隐私政策》（以下简称“本政策”）并提醒您：
          </p> <br>
          <p class="indent"> 本政策适用于危险品安全联盟小程序及
            https://gbk.aila.site/网站提供的所有产品和服务。</p> <br>
          <p class="indent">   需要特别说明的是，本政策不适用于其他第三方向您提供的服务，也不适用于您依托小程序或者DGA平台向第三方提供的个人信息。</p> <br>
          <p class=" strong"> 在使用DGA平台提供的各项产品和服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。您使用我们的服务，即意味着您已经同意我们按照本隐私政策收集、使用、储存和披露您的相关信息，以及向您提供的控制和保护措施。</p><br>

          <p class=" strong"> 第一部分 概述 </p><br> <p class=" strong">本隐私权政策部分将帮助您了解以下内容：</p><br>
          <p class=" strong"> 一、个人信息的初始收集和保存</p><br>
          <p class=" strong"> 二、我们如何使用Cookie和同类技术进行信息的持续收集</p><br>
          <p class=" strong"> 三、我们如何使用您的信息 四、我们如何共享、转让、公开披露您的信息</p><br>
          <p class=" strong"> 五、您的信息如何在全球范围转移 六、我们如何保护您的信息</p><br>
          <p class=" strong"> 七、您如何管理您的信息 八、我们如何处理未成年人的信息</p><br>
          <p class=" strong"> 九、本政策如何更新 十、如何联系我们 第二部分 平台隐私政策</p><br>
          <p class=" strong"> 一、个人信息的初始收集和保存</p><br>
          1.为完成账户的注册、管理、实名认证等必要活动，您需要提交真实、合法、有效的信息，包括但不限于名称、联系人、联系电话、电子邮箱、联系地址、工商登记信息、银行账户信息等。<br>
          2.
          为提供持续服务和保证服务质量所需，我们在您使用服务期间，将记录并保存您登录和使用服务的信息，包括但不限于IP地址、使用的语言、访问日期和时间及网页浏览记录等。<br>
          3.
          如果您需要使用部分可选的服务，如一对一在线视频洽谈服务等，您还应该提供使用该功能或服务所必须的信息，如联系人、联系方式、身份证、照片、设备识别号等。<br>
          <p class=" strong">二、我们如何使用Cookie和同类技术进行信息的持续收集</p><br> 1.
          我们通过您主动提交，以及在您使用我们的服务过程中产生相应的记录等方式收集您的信息。<br>
          2. 我们会在您的计算机或移动设备上存储Cookie、Flash
          Cookie，或浏览器（或关联应用程序）提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。我们可能会通过Cookies和其他相关技术收集和使用您的信息。我们使用Cookies的具体用途包括：
          <br> a)
          识别您的身份。例如：Cookies有助于我们辨认您作为我们的注册用户的身份。<br>
          b)
          分析您使用我们服务的情况，以便为您提供更加周到的个性化服务，包括定制化页面、推荐等服务。<br>
          3. 您可以通过浏览器设置拒绝或管理 Cookies。但请注意，如果停用
          Cookies，您有可能无法享受最佳的服务体验，某些功能的可用性可能会受到影响。<br>
          4. 通过平台 Cookies 记录的有关信息，将适用本隐私政策。
          <p class=" strong">  三、我们如何使用您的信息</p><br>
          为了向您提供更加优质、便捷、安全的服务，在符合相关法律法规的前提下，我们可能将收集的信息用作以下用途：<br>
          1. 向您提供服务。<br> 2.
          满足您的个性化需求。例如，语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。<br>
          3.
          服务优化和开发。例如，我们会根据平台系统响应您的需求时产生的信息，优化我们的服务。<br>
          4.
          保护平台、平台用户和平台的合作伙伴。例如，我们会将您的信息用于身份验证、安全防范、投诉处理、纠纷协调、诈骗监测等用途。<br>
          5.
          其他可能需要使用收集的信息的相关场景，如果使用场景与初始场景无合理的关联性，我们会在使用信息前重新征得您的同意。<br>
          <p class=" strong"> 四、我们如何共享、转让、公开披露您的信息</p><br>  <p class=" strong"> （一） 共享</p><br>
          未经您同意，我们不会与DGA平台服务提供者以外的公司、组织和个人共享您的个人信息，但下列情形除外：<br>
          1.
          在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。<br>
          2.
          在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br>
          3.
          在您主动选择情况下共享：您通过平台进行交易，我们会根据您的选择，将您的个人信息中与交易有关的必要信息共享给相关服务的提供者，以实现您的交易需求。<br>
          4．与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。
          目前，我们的授权合作伙伴包括以下类型：<br>
          （1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享。我们会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息，但不会提供您的个人身份信息，或者我们将这些信息进行去标识化处理，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。
          <br> （2）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。
          我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。
          <br> <p class=" strong"> （二） 转让</p><br>
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br>  1.
          在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。<br>
          2.
          在DGA平台服务提供者发生合并、收购、资产转让、破产清算或类似情形，您的信息有可能作为此类交易的一部分而被转移。我们将遵守相关法律法规的规定，并要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
          <br> <p class=" strong"> （三）公开披露 </p><br> 1.
          在获取明确同意或基于您的主动选择，我们可能会公开披露您的个人信息。<br>  2.
          如果我们发现您存在违反法律法规或严重违反DGA平台小程序及网站平台相关协议、规则的情况，或为保护平台用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息，包括公开披露您的相关违规行为以及平台已对您采取的措施。
          <p class=" strong"> （四）共享、转让、公开披露个人信息时无需事先征得您授权同意的例外情形</p><br>
          1. 与国家安全、国防安全有关的。<br> 2.
          与公共安全、公共卫生、重大公共利益有关的。<br> 3.
          与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的。<br> 4.
          出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。<br>
          5. 您自行向社会公众公开的个人信息。<br> 6.
          从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
          <br> 7.
          为执行相关服务协议或本隐私政策、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。
          请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。
          <p class=" strong"> 五、您的信息如何在全球范围转移</p><br> 1.
          平台收集的有关您的信息保存在平台位于中国大陆的服务器，以下情形除外：<br>
          a) 适用的法律有明确规定。<br> b) 获得您的明确授权。<br> c)
          您通过互联网进行跨境交易等个人主动行为。<br>
          针对以上情形，我们会确保依据本政策对您的个人信息提供足够的保护。<br> 2.
          通常，我们仅在为您提供服务期间保留您的信息，保留时间不会超过满足相关使用目的所必须的时间。但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：
          a) 遵守适用的法律法规等有关规定。<br> b)
          遵守法院判决、裁定或其他法律程序的要求。<br> c)
          遵守相关政府机关或其他有权机关的要求。<br> d)
          我们有理由确信需遵守法律法规等有关规定。<br> e)
          为执行相关服务协议或本隐私政策、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。
          <p class=" strong"> 六、我们如何保护您的信息 </p><br>1.
          我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。为保障信息的安全，我们使用了各种安全技术，并建立了严格的管理制度和流程。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网环境下，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
          若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以发送邮件、推送通知、公告等形式告知您相关情况，并向您给出安全建议。
          <br>2.
          为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识。我们仅在平台直接导致您个人信息泄露的范围内承担责任，因此，请您妥善保管您的账号及密码信息，避免您的个人信息泄露。
          <p class=" strong">七、您如何管理您的信息 </p><br>1.
          您可以在使用我们服务的过程中，访问、修改和删除您的相关信息。您访问、修改和删除信息的方式将取决于您使用的具体服务。
          <br>2.
          如您发现我们违反法律法规的规定或者双方的约定收集、使用您的信息，您可以要求我们删除。如您发现我们收集、存储的您的信息有错误的，且无法自行更正的，您也可以要求我们更正。
          <br>3.
          在访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障账户安全。
          <br>4.
          请您理解，由于技术所限、基于法律法规要求，您的某些请求可能无法进行响应。
          <br>5.
          您可以自行选择撤回对某些非基本功能或服务对您信息处理的同意，并通过联系客服的方式选择注销平台账号。
          <br> 6. 在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br> a)
          与国家安全、国防安全有关的；<br> b)
          与公共安全、公共卫生、重大公共利益有关的；<br> c)
          与犯罪侦查、起诉、审判和执行判决等有关的；<br> d)
          有充分证据表明个人信息主体存在主观恶意或滥用权利的；<br> e)
          响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；<br> f)
          涉及商业秘密的。
          若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
          当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。
          <p class=" strong"> 八、我们如何处理未成年人的信息</p><br>
          在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
          对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
          <p class=" strong">  九、本政策如何更新</p><br>
          我们可能适时修订本隐私政策的条款，该等修订构成本政策的一部分。如可能造成您在本隐私政策下权利的实质减少或扩大收集、使用信息的范围等重要规则变更时，我们将在修订生效前通过在主页上显著位置提示或向您提供弹窗提示。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本隐私政策的约束。
          <p class=" strong"> 十、与我们联系</p><br>
          如您对本政策或其他相关事宜有疑问，请根据我们提供的指引，填写相关资料，将您的问题发送至info@ailaworld.com
          。 我们将尽快审核所涉问题，并在验证您的用户身份后的三十日内予以回复。
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
import fileUpload from "~/baseComponents/fileUpload";
import { validateTelephone } from "~/baseUtils/validate";
export default {
  props: ["id"],
  components: { fileUpload },
  data() {
    var validateTel = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(" 111"));
      }
      if (!validateTelephone(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      innerVisible: false,
      dialogVisible: false,
      form: {
        name: "",
        tel: "",
        position: {
          file_name: "",
          url: "",
        },
      },
      rules: {
        tel: [
          { required: true, message: " ", trigger: "change" },
          { validator: validateTel, trigger: "blur" },
        ],
      },
      fileUploadOptions: {
        accept: ".jpg,.jpeg,.bmp,.png,.doc,.docx,.zip,.rar",
        autoUpload: true,
        limitSize: 8,
      },
    };
  },
  methods: {
    resetForm() {
      this.$refs["numberValidateForm"].resetFields();
    },
    uploadComplete(result) {
      console.log(result);
      this.form.position = {
        file_name: result.name,
        url: result.url,
      };
    },
    close() {
      this.form = {
        name: "",
        tel: "",
        position: {
          file_name: "",
          url: "",
        },
      };
      this.resetForm();
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.form.position.file_name) {
            this.$message({
              showClose: true,
              message: "必须上传电子简历",
              type: "warning",
            });
            return;
          }
          let parmas = {
            name: this.form.name,
            mobile: this.form.tel,
            source: this.PJSource,
            position_id: this.id,
            attachment_list: JSON.stringify([this.form.position]),
          };
          let data = await this.$store.dispatch(
              "API_position/submit_position",
              parmas
          );
          console.log(data);
          if (data.success) {
            this.$message({
              message: "投递成功",
              type: "success",
            });
            this.dialogVisible = false;
          }
          if (data.errorcode == 1000) {
            this.$message({
              message: "您已投递过该职位",
              type: "warning",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
p{
  word-wrap: break-word;
}
.Tcenter{
  text-align: center;
}
.indent{
  text-indent: 30px;
}
.strong{
  font-weight: 900;
}
.itemTitle {
  font-size: 14px;
  span {
    color: red;
  }
}
.tips {
  line-height: 20px !important;
  font-size: 12px;
  color: #999;
}
.upPosition {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  i {
    cursor: pointer;
  }
}
.btns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .btn {
    width: 160px;
  }
}
</style>