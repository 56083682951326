<template>
  <div class="container">
    <div class="content_area" ref="list">
      <filterSearch ref="search" :inputPlaceholder="'请输入职位'" @handleSearch="handleSearch"/>
      <div class="clearfix">
        <div v-loading="loading" class="fl left_list">
          <positionList :dataList="jobList" :showType="1"/>
          <div class="pagination">
            <el-pagination
                layout="prev, pager, next"
                :total="count"
                background
                :current-page.sync="params.start"
                :page-size="10"
                :hide-on-single-page="true"
                @current-change="currentChange"
            />
          </div>
        </div>
        <div class="fr right_list">
          <viewPost v-if="IS_LOGIN" :positionList="positionHistoryList"/>
          <advertising/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import positionList from '~gbk/components/position/positionList'
  import viewPost from "~scr/components/booth/viewPost";
  import advertising from '~gbk/components/position/advertising'
  import filterSearch from "~gbk/components/common/filterSearch_positionList";

  export default {
    metaInfo: {
      title: '人才专区'
    },
    components: {
      positionList,
      filterSearch,
      viewPost,
      advertising
    },
    data() {
      return {
        jobList: [],
        params: {
          level_require: '',
          company_name: '',
          salary_highest: '',
          salary_lowest: '',
          city: '',
          scale: '',
          start: 1,
          limit: 10,
          nopage: 0
        },
        count: 0,
        loading: false,
       positionHistoryList: []
      }
    },
    mounted() {
      document.title='校企合作'
      this.INFO_MATION()
      // 从别的地方跳转过来
      let query = this.$route.query

      
      if (query) {
        this.params.city = query.city
        this.params.company_name = query.content
        this.$refs.search.initParams(query)
      }
      this.getDataList()
      this.getPositionHistoryList()
    },
    methods: {
      async getPositionHistoryList() {
        let params = {
          nopage: 0,
          limit: 5,
          start: 0,
          source: this.PJSource
        }
        params.user_id = this.$store.state.baseStore.userId
        let res = await this.$store.dispatch("API_position/getJobHistoryPositionList", params);
        if (res&&res.success) {
          this.positionHistoryList = res.data
        }
      },
      async getDataList() {
        this.loading = true
        let params = Object.assign({}, this.params)
        params.start = params.start - 1
        // params.user_id = this.$store.state.baseStore.userId
        // params.company_id = this.USER_INFO.company_id

        params.source=this.PJSource
        let res = await this.$store.dispatch("API_position/getJobPositionList", params);
        this.loading = false
        if (res&&res.success) {
          this.jobList = res.data
          this.count = res.count
        }
      },
      handleSearch(params) {
        params.level_require = params.levelRequire
        params.scale = params.staffSize
        params.company_name = params.content
        params.source=this.PJSource
        if (params.salary) {
          params.salary_lowest = params.salary.min
          params.salary_highest = params.salary.max
          delete params.salary
        } else {
          params.salary_lowest = ''
          params.salary_highest = ''
        }
        delete params.content
        delete params.levelRequire
        delete params.staffSize
        this.params = Object.assign(this.params, params)

        this.params.start = 1
        this.getDataList()
      },
      currentChange(index) {
        this.$nextTick(() => {
          this.$refs.list.scrollIntoView();
        })
        this.params.start = index;
        this.getDataList()
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    padding: 30px 0;
    background: #F2F4F6;
  }

  .left_list {
    width: 884px;
    margin-top: 16px;
  }

  .right_list {
    width: 300px;
  }
  .pagination {
    margin: 20px auto 60px;
    display: flex;
    flex-direction:row-reverse;
  }
</style>